import { template as template_47cc5f2aa9644f9da6fcdbbaee2ffc38 } from "@ember/template-compiler";
const FKLabel = template_47cc5f2aa9644f9da6fcdbbaee2ffc38(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
