import { template as template_c04c0e680be448d494a76901d7c18957 } from "@ember/template-compiler";
const FKText = template_c04c0e680be448d494a76901d7c18957(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
