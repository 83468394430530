import { template as template_a85668dcb21345d7a24b34074978fcfa } from "@ember/template-compiler";
const WelcomeHeader = template_a85668dcb21345d7a24b34074978fcfa(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
